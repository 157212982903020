import {
  Auth,
  CompositeLayer,
  DefaultMapLayer,
  DefaultPolygonsLayer,
  DefaultStatisticsLayer,
  DefaultStatisticsLayerOptions,
  DefaultStatisticsTravelLayer,
  DefaultStatisticsTravelLayerOptions,
  EMPTY_GEOJSON_DATA,
  FeatureInteractionLayer,
  GeojsonMapSource,
  getBasePath,
  Indicators,
  MapCellEvent,
  MapInteractionEvent,
  MapLayerPosition,
  MarkerMapLayer,
  PolygonMapLayerOptions,
  TileMapSource,
  InteractionLayer,
} from '@targomo/client'
import { LatLngProperties, SRID } from '@targomo/core'
import { combineLatest, merge } from 'rxjs'
import 'rxjs/add/operator/switchMap'
import { Observable } from 'rxjs/Observable'
import { debounceTime, delay, distinctUntilChanged, map, shareReplay } from 'rxjs/operators'
import { PlanningApplication } from '../../../common/models'
import { canonicalPositions } from '../../api/place'
import { AppModel } from '../../model/appModel.service'
import { GREAT_BRITAIN_HEX_100M, GREAT_BRITAIN_HEX_500M, STATISTICS } from '../../model/constants'
import { FeatureModel } from '../../model/featureModel'
import { DataSetLocation } from '../../model/index'
import { markersLayer } from '../../model/markersLayer'
import { POLYGON_SOURCES_THRESHOLD } from '../../model/placesModel'
import { TravelDisplayMode } from '../../model/settingsModel'
import { ZoneLayersModel } from '../../model/zoneLayersModel'
import { LoopMarkerInteractionLayer } from './markers-interaction-layer'
import { MultigraphLayer } from './multigraphLayer'
import { SymbolMarkerMapLayer } from './symbolMarkerLayer'
import { ZoneLayerComposite } from './zoneLayerComposite'
import { CustomMapboxComponent } from '../mapBox/mapbox.component'
import { MobileCatchmentsComposite } from './mobileCatchmentsComposite'
import { MobileCatchmentsEndpoint } from '../../api/mobileCatchments'

const HYBRID_ICON_MYSTERY = 10

function translateStyle(style: any) {
  return ['match', ['get', style.property], ...Array.prototype.concat.apply([], style.stops), style.default]
}

export function getGroupForZoom(zoom: number) {
  if (zoom >= 12) {
    return GREAT_BRITAIN_HEX_100M
  } else {
    return GREAT_BRITAIN_HEX_500M
  }
}

export class MainMapLayer extends CompositeLayer {
  public readonly events: {
    contextPlanning: Observable<MapInteractionEvent<PlanningApplication>>
    clickPlanning: Observable<MapInteractionEvent<PlanningApplication>>
    hoverPlanning: Observable<MapInteractionEvent<PlanningApplication>>
    clickLocation: Observable<MapInteractionEvent<DataSetLocation>>
    clickLocationAlt: Observable<MapInteractionEvent<DataSetLocation>>
    hoverLocation: Observable<MapInteractionEvent<DataSetLocation>>
    contextLocation: Observable<MapInteractionEvent<DataSetLocation>>
    contextLocationAlt: Observable<MapInteractionEvent<DataSetLocation>>
    hoverCell: Observable<MapCellEvent>
    clickMaybeMap: Observable<MapInteractionEvent<DataSetLocation>>
  }

  private markersLayers: (MarkerMapLayer<any> | SymbolMarkerMapLayer<any>)[] = []
  private mainMarkersLayer: MarkerMapLayer<LatLngProperties>
  private deletedMarkersLayer: MarkerMapLayer<LatLngProperties>
  private symbolMarkersLayer: SymbolMarkerMapLayer<LatLngProperties>

  // For example because planning applications only are to be shown
  private hidePlaces = this.initHidePlaces()

  constructor(
    map: CustomMapboxComponent,
    private appModel: AppModel,
    private featureModel: FeatureModel,
    private zoneLayersModel: ZoneLayersModel,
    private indicators: Indicators,
    private auth: Auth,
    private modileCatchmentsEndpoint: MobileCatchmentsEndpoint
  ) {
    super(map as any)

    const statisticsLayer = this.initStatistics()
    this.initTravel(statisticsLayer)
    this.initPolygons()
    this.initMobile()

    const multigraphBoth = this.initMultigraph()

    const planningLayer = this.initPlanningLayer()
    const [markersLayer, deletedLayer] = this.initMarkers()
    this.mainMarkersLayer = markersLayer
    this.deletedMarkersLayer = deletedLayer
    const customMarkersLayers = this.initCustomMarkers()
    this.symbolMarkersLayer = customMarkersLayers.layer

    this.initSourcesMarkers()
    const selectAltLayer = this.initHoverMarkers()
    const temporaryMarker = this.initTemporaryMarker()

    const markersInteraction = new LoopMarkerInteractionLayer([
      markersLayer,
      customMarkersLayers.layer,
      deletedLayer,
      customMarkersLayers.layerHoverFat,
    ])
    const markersInteractionAlt = new LoopMarkerInteractionLayer([selectAltLayer])
    const planningInteraction = new LoopMarkerInteractionLayer([planningLayer])

    this.initSectors()
    this.initExtra()

    this.events = <any>{
      clickPlanning: planningInteraction.events.click,
      contextPlanning: planningInteraction.events.context,
      hoverPlanning: planningInteraction.events.hover,
      clickLocationAlt: markersInteractionAlt.events.click,
      clickLocation: markersInteraction.events.click,
      clickMaybeMap: markersInteraction.events.clickMaybeMap,
      hoverLocation: markersInteraction.events.hover,
      contextLocation: markersInteraction.events.context,
      contextLocationAlt: markersInteractionAlt.events.context,
      hoverCell: merge(statisticsLayer.interaction.events.hover, multigraphBoth.interaction.events.hover),
    }
  }

  private initHidePlaces() {
    return combineLatest(
      this.appModel.places.planningApplicationsPlaces,
      this.appModel.places.selectedPlacePlanningRisk
    )
      .map(([planning, riskSelection]) => {
        return planning !== null && riskSelection === null
      })
      .distinctUntilChanged()
      .shareReplay(1)
  }

  private initExtra() {
    this.watch(
      combineLatest(
        this.zoneLayersModel.editingUpdates,
        this.zoneLayersModel.editingDrawnUpdates,
        this.appModel.settings.markerStyleReportUpdates
      ),
      ([editing, editingDrawn, reportMode]) => {
        this.markersLayers.forEach((layer) => layer.setVisible(!editing && !editingDrawn))

        const hideForReport = reportMode === 'hidden'

        // hide for print
        this.mainMarkersLayer.setVisible(!editing && !editingDrawn && !hideForReport)
        this.deletedMarkersLayer.setVisible(!editing && !editingDrawn && !hideForReport)
        this.symbolMarkersLayer.setVisible(!editing && !editingDrawn && !hideForReport)
      }
    )
  }

  private async initSectors() {
    const field = (await this.auth.isAdmin()) ? 'preview' : 'active'
    const layer = new ZoneLayerComposite(
      this.map as any,
      this.appModel.settings.showLabelsUpdates,
      this.appModel.settings.zoneLayerUpdates,
      this.zoneLayersModel,
      field,
      this.featureModel,
      this.appModel.settings.showSectorsTilesLayersUpdates
    )

    this.watch(
      Observable.combineLatest(this.appModel.settings.showSectorsUpdates, this.appModel.settings.zoneLayerUpdates),
      ([visible, zone]) => {
        layer.setVisible(visible) //  && zone != null)
      }
    )

    return layer
  }

  private getStyleObservable(which: 'markerStyle' | 'sourceMarkerStyle') {
    return Observable.combineLatest(
      this.appModel.places.uniqueColors,
      this.appModel.settings.markerStylePrintUpdates,
      this.appModel.settings.markerSizePropertyUpdates
    ).map(([uniqueColors, forPrint, property]) =>
      uniqueColors.length ? (markersLayer(uniqueColors, forPrint, property) as any)[which] : null
    )
  }

  private getStyleObservableFactor(which: 'markerStyle' | 'sourceMarkerStyle') {
    return combineLatest(
      this.getStyleObservable(which),
      (this.map as any as CustomMapboxComponent).style$.pipe(
        distinctUntilChanged(),
        map((item) => {
          return String(item).indexOf('hybrid') !== -1 ? HYBRID_ICON_MYSTERY : 1
        }),
        shareReplay(1)
      )
    )
  }

  initMarkers() {
    const style = this.getStyleObservable('markerStyle').pipe(
      map((s) => {
        if (s && s['circle-color']) {
          s['circle-color'] = [
            'case',
            ['has', 'marker-icon-color'],
            ['get', 'marker-icon-color'],
            translateStyle(s['circle-color']),
          ]
        }

        return s
      })
    )

    const deletedStyle = style.map((value) => {
      return {
        ...value,
        'circle-opacity': 0.2,
        'circle-stroke-opacity': 0.4,
      }
    })

    const styleDeletedSymbol = this.getStyleObservable('markerStyle').map((style) => {
      // let sizeCategories: any = 0
      // if (style && style['circle-radius'] && style['circle-radius'].stops) {
      //   sizeCategories = { ...style['circle-radius'] }
      //   sizeCategories.stops = sizeCategories.stops.map((stop: any) => {
      //     return [stop[0], stop[1] / 30]
      //   })
      // }

      const layout = {
        'icon-image': 'delete',
        'symbol-placement': 'point',
        'icon-allow-overlap': true,
        'icon-size': {
          stops: [
            [5, 0.05],
            [25, 0.3],
          ],
        },
        'icon-ignore-placement': true,
        'text-ignore-placement': true,
      }

      const paint: any = {}

      if (style && style['circle-color']) {
        paint['icon-color'] = '#910303'
        paint['icon-opacity'] = 0.7
      }

      return {
        paint,
        layout,
      }
    })

    const deletedMarkersSource = combineLatest(this.appModel.places.filteredPlacesDeleted.value, this.hidePlaces).map(
      ([places, hide]) => {
        if (hide) {
          return []
        } else {
          return places
        }
      }
    )

    const markersSource = combineLatest(this.appModel.places.filteredPlacesNormal, this.hidePlaces).map(
      ([places, hide]) => {
        if (hide) {
          return []
        } else {
          return places
        }
      }
    )

    const deletedLayer = new MarkerMapLayer(this.map, <any>deletedMarkersSource, deletedStyle).setPosition(
      MapLayerPosition.MARKERS
    )
    this.markersLayers.push(deletedLayer)

    const deletedSymbolLayer = new SymbolMarkerMapLayer(
      this.map as any,
      <any>deletedMarkersSource,
      styleDeletedSymbol
    ).setPosition(MapLayerPosition.MARKERS)
    this.markersLayers.push(deletedSymbolLayer)

    const layer = new MarkerMapLayer(this.map, <any>markersSource, style).setPosition(MapLayerPosition.MARKERS)
    this.markersLayers.push(layer)
    return [layer, deletedLayer]
  }

  initPlanningLayer() {
    // const style= this.getStyleObservable('markerStyle')
    const hoverStyle = {
      'circle-radius': {
        stops: [
          [5, 1],
          [25, 28],
        ],
      },
      'circle-color': '#3887be',
      'circle-stroke-width': 2,
      'circle-stroke-color': 'white',
    }

    const layer = new MarkerMapLayer(this.map, <any>this.appModel.places.planningApplicationsPlaces).setPosition(
      MapLayerPosition.MARKERS
    )
    const layerHover = new MarkerMapLayer(
      this.map,
      <any>this.appModel.places.hoverPlanningPlace.map((hover) => (hover ? [hover] : [])),
      hoverStyle
    ).setPosition(MapLayerPosition.MARKERS)

    return layer
  }

  initCustomMarkers() {
    const updateMapIcons = async () => {
      try {
        const map = await this.map.getMap()

        const icons = [
          'marker_icon_0',
          'marker_icon_10',
          'marker_icon_11',
          'marker_icon_12',
          'marker_icon_13',
          'marker_icon_1',
          'marker_icon_2',
          'marker_icon_3',
          'marker_icon_4',
          'marker_icon_5',
          'marker_icon_6',
          'marker_icon_7',
          'marker_icon_8',
          'marker_icon_9',
        ]

        icons.forEach((key) => {
          if (!(map as any).hasImage(key)) {
            map.loadImage(getBasePath() + 'assets/images/marker_icons/' + key + '.png', (err: string, image: any) => {
              if (!(map as any).hasImage(key)) {
                map.addImage(key, image, <any>{ sdf: true })
              }
            })
          }
        })
        ;['star', 'delete', 'marker'].forEach((key) => {
          if (!(map as any).hasImage(key)) {
            map.loadImage(getBasePath() + 'assets/images/' + key + '-sdf.png', (err: string, image: any) => {
              if (!(map as any).hasImage(key)) {
                map.addImage(key, image, <any>{ sdf: true })
              }
            })
          }
        })
        ;['car-side-solid-border'].forEach((key) => {
          if (!(map as any).hasImage(key)) {
            map.loadImage(getBasePath() + 'assets/images/' + key + '.png', (err: string, image: any) => {
              if (!(map as any).hasImage(key)) {
                map.addImage(key, image, <any>{ sdf: false })
              }
            })
          }
        })
      } catch (e) {
        console.error(e)
      }
    }

    updateMapIcons()

    // several tries to load images
    const delays = [10, 200, 1000, 4000]
    delays.map((time) => this.watch(this.appModel.settings.mapStyleUpdates.pipe(delay(time)), () => updateMapIcons()))

    const style = this.getStyleObservableFactor('markerStyle').map(([style, factor]) => {
      let sizeCategories: any = 0
      if (style && style['circle-radius']) {
        sizeCategories = JSON.parse(JSON.stringify([...style['circle-radius']]))
        const factorPart = ['case', ['has', 'marker-icon-type'], 30, 30 / factor]

        sizeCategories[4] = ['/', sizeCategories[4], factorPart]
        sizeCategories[6] = ['/', sizeCategories[6], factorPart]
      }

      const layout = {
        'icon-image': ['case', ['has', 'marker-icon-type'], ['get', 'marker-icon-type'], 'star'],
        'symbol-placement': 'point',
        'icon-allow-overlap': true,
        'icon-size': sizeCategories,
        'icon-ignore-placement': true,
        'text-ignore-placement': true,
      }

      const paint: any = {}

      if (style && style['circle-color']) {
        paint['icon-color'] = [
          'case',
          ['has', 'marker-icon-color'],
          ['get', 'marker-icon-color'],
          translateStyle(style['circle-color']),
        ]
      }

      return {
        paint,
        layout,
      }
    })

    const styleFat = this.getStyleObservableFactor('markerStyle').map(([style, factor]) => {
      let sizeCategories: any = 0
      if (style && style['circle-radius']) {
        sizeCategories = JSON.parse(JSON.stringify([...style['circle-radius']]))

        const factorPart = ['case', ['has', 'marker-icon-type'], 25, 25 / factor]

        sizeCategories[4] = ['/', sizeCategories[4], factorPart]
        sizeCategories[6] = ['/', sizeCategories[6], factorPart]
      }

      const layout = {
        'icon-image': ['case', ['has', 'marker-icon-type'], ['get', 'marker-icon-type'], 'star'],
        'symbol-placement': 'point',
        'icon-allow-overlap': true,
        'icon-size': sizeCategories,
        // 'icon-size': style && style['circle-radius'] ? ['/', style['circle-radius'], 25] : 1,
        'icon-ignore-placement': true,
        'text-ignore-placement': true,
      }

      const paint = {
        'icon-color': '#fff', //style && style['circle-color'],
      }

      return {
        paint,
        layout,
      }
    })

    const hoverAndSources = Observable.combineLatest(
      this.appModel.places.hoverPlace,
      this.appModel.places.selectedPlace,
      this.appModel.places.sources.observableCombined
    ).map(([hover, selected, sources]) => {
      return (
        (hover ? [hover] : [])
          .concat(selected ? [selected] : [])
          // .concat(sources).filter(place => place.id < 0)
          .concat(sources)
          .filter((place) => place.isStar())
      )
    })

    const markersSource = combineLatest(
      this.appModel.places.filteredPlacesCustom,
      this.hidePlaces,
      this.appModel.settings.markerStyleReportUpdates,
      this.appModel.places.sources.observableCombined
    ).map(([places, hide, reportStyle, sources]) => {
      if (reportStyle === 'hidden') {
        return []
      }

      if (hide) {
        return []
      } else {
        if (reportStyle === 'competition') {
          return places.filter((place) => !place.planningLocation)
        }
        return places
      }
    })

    const layer = new SymbolMarkerMapLayer(this.map as any, <any>markersSource, style).setPosition(
      MapLayerPosition.MARKERS
    )
    layer.setVisible(true)

    const layerHoverFat = new SymbolMarkerMapLayer(this.map as any, <any>hoverAndSources, styleFat).setPosition(
      MapLayerPosition.SOURCES
    )
    const layerHover = new SymbolMarkerMapLayer(this.map as any, <any>hoverAndSources, style).setPosition(
      MapLayerPosition.SOURCES
    )

    // this.markersLayers.push(layer)
    return { layer, layerHoverFat }
  }

  initSourcesMarkers() {
    const style = this.getStyleObservable('sourceMarkerStyle')
    const items = this.appModel.places.sources.observableCombined.map((list) => {
      // return list && list.filter(item => item.id >= 0)
      return list && list.filter((item) => !item.isStar())
    })

    const layer = new MarkerMapLayer(this.map, <any>items, style).setPosition(MapLayerPosition.SOURCES)
    this.markersLayers.push(layer)
    return layer
  }

  initTemporaryMarker() {
    const layout = {
      'icon-image': 'marker',
      'symbol-placement': 'point',
      'icon-allow-overlap': true,
      'icon-size': ['interpolate', ['linear'], ['zoom'], 5, 2 / 25, 25, 9 / 25],
      'icon-ignore-placement': true,
      'text-ignore-placement': true,
      'icon-anchor': 'bottom',
    }

    const paint = {
      'icon-color': '#f47a6b',
    }

    const style = {
      paint,
      layout,
    }

    const items = this.appModel.places.temporaryLocation.pipe(
      map((location) => {
        return location ? [location] : []
      })
    )

    const layer = new SymbolMarkerMapLayer(this.map as any, <any>items, style).setPosition(MapLayerPosition.SOURCES)
    this.markersLayers.push(layer)
    return layer
  }

  initHoverMarkers() {
    const style = this.getStyleObservable('sourceMarkerStyle')
    const items = this.appModel.places.hoverPlace
      .map((place) => (place ? [place] : []))
      .map((list) => {
        return list && list.filter((item) => !item.isStar())
        // return list && list.filter(item => item.id >= 0)
      })

    const itemsSelected = this.appModel.places.selectedPlace
      .map((place) => (place ? [place] : []))
      .map((list) => {
        // return list && list.filter(item => item.id >= 0)
        return list && list.filter((item) => !item.isStar())
      })

    const itemsSelectedAlt = this.appModel.places.selectedPlacePlanningRisk
      .map((place) => (place ? [place] : []))
      .map((list) => {
        // return list && list.filter(item => item.id >= 0)
        return list && list.filter((item) => !item.isStar())
      })

    const layer = new MarkerMapLayer(this.map, <any>items, style).setPosition(MapLayerPosition.HOVER)
    const layerSelect = new MarkerMapLayer(this.map, <any>itemsSelected, style).setPosition(MapLayerPosition.HOVER)
    const layerSelectAlt = new MarkerMapLayer(this.map, <any>itemsSelectedAlt, style).setPosition(
      MapLayerPosition.HOVER
    )
    this.markersLayers.push(layer)
    this.markersLayers.push(layerSelect)
    this.markersLayers.push(layerSelectAlt)

    return layerSelectAlt
  }

  initStatistics() {
    const settingsStore = this.appModel.settings

    const options: Observable<DefaultStatisticsLayerOptions> = Observable.combineLatest(
      settingsStore.statisticUpdates,
      settingsStore.cellHoverUpdates,
      // settingsStore.allStatisticsKeysUpdates,
      settingsStore.interpolatorUpdates,
      settingsStore.inverseTravelUpdates,
      settingsStore.isochronesUpdates,
      settingsStore.statisticsOpacityUpdates,
      (statistic, cellHover, interpolator, inverse, isochrones, opacity) => {
        let statistics = [statistic]
        if (!statistic || statistic.id < 0) {
          statistics = [STATISTICS[0]] //
        }

        // <any> because combineLatest only has type overrides up to 6 parameters
        return <any>{
          statistic,
          statistics,
          cellHover,
          interpolator,
          opacity,
          grayscale: !isochrones && inverse,

          getGroupForZoom,
        }
      }
    )

    const layer = new DefaultStatisticsLayer(this.map, <any>settingsStore.clientStatistics, options)

    const visible$ = combineLatest(this.appModel.multigraph.tilesUrl$, this.featureModel.travelDisplayModeUpdates).pipe(
      map(([multigraph, mode]) => {
        return (
          mode !== TravelDisplayMode.NoThematicPolygons && !multigraph && mode !== TravelDisplayMode.MobileCatchment
        )
      }),
      shareReplay(1)
    )

    this.watch(visible$, (visible) => {
      layer.setVisible(visible)
    })

    return layer
  }

  initTravel(statistics: DefaultStatisticsLayer) {
    const settingsStore = this.appModel.settings
    const options: Observable<DefaultStatisticsTravelLayerOptions> = combineLatest(
      settingsStore.statisticUpdates,
      settingsStore.interpolatorUpdates,
      settingsStore.inverseTravelUpdates,
      settingsStore.isochronesUpdates,
      this.appModel.places.sources.observableCombined,
      settingsStore.travelOptionsUpdates,
      // settingsStore.travelTypeDistanceUpdates,
      settingsStore.edgeWeightsUpdates,
      settingsStore.travelOpacityUpdates,
      settingsStore.intersectionModeUpdates,
      settingsStore.exclusiveTravelUpdates,
      this.appModel.places.inactiveSources
    )
      .debounceTime(10)
      .switchMap(
        async ([
          statistic,
          interpolator,
          inverse,
          isochrones,
          sources,
          travelTypeEdgeWeight,
          edgeWeights,
          opacity,
          intersectionMode,
          exclusiveMode,
          inactiveSources,
        ]) => {
          if (sources && (<any>sources).length > POLYGON_SOURCES_THRESHOLD) {
            return {
              statistic,
              interpolator,
              sources: [],
              opacity,
              travelOptions: { ...travelTypeEdgeWeight },
              edgeWeights,
              inverseTravel: !isochrones && inverse,
              intersectionMode: 'union',
              inactiveSources: [],
            }
          }

          // Cast because combineLatest has propert types only up to 6 params
          return <any>{
            statistic,
            interpolator,
            sources: canonicalPositions(<any>sources),
            opacity,
            travelOptions: { ...travelTypeEdgeWeight },
            edgeWeights,
            inverseTravel: !isochrones && inverse,
            intersectionMode,
            inactiveSources,
            // inactiveSources: await this.appModel.places.getInactiveSources(exclusiveMode, sources, travelTypeEdgeWeight)
          }
        }
      )

    const layer = new DefaultStatisticsTravelLayer(
      this.map,
      statistics.layer,
      <any>settingsStore.client,
      options,
      this.indicators
    )

    // this.watch(this.featureModel.travelDisplayModeUpdates, mode => {
    //   layer.setVisible(mode === TravelDisplayMode.ThematicNoPolygons)
    // })

    const visible$ = combineLatest(
      this.appModel.multigraph.tilesUrl$,
      this.featureModel.statisticsTravelVisibleUpdates
    ).pipe(
      map(([multigraph, visible]) => {
        return visible && !multigraph
      }),
      shareReplay(1)
    )

    this.watch(visible$, (visible) => {
      layer.setVisible(visible)
    })

    return layer
  }

  initMultigraph() {
    const source = new TileMapSource(this.appModel.multigraph.tilesUrl$)
    const layer = new MultigraphLayer(
      this.map as any,
      source,
      this.appModel.multigraph.overview$,
      this.appModel.settings.edgeWeightsUpdates,
      this.appModel.settings.interpolatorUpdates,
      this.appModel.settings.travelOpacityUpdates,
      this.appModel.settings.statisticsOpacityUpdates,
      this.appModel.settings.travelDisplayModeUpdates,
      this.appModel.multigraph.tilesUrl$
    )

    const visible$ = combineLatest(this.appModel.multigraph.tilesUrl$, this.featureModel.travelDisplayModeUpdates).pipe(
      map(([multigraph, mode]) => {
        return mode !== TravelDisplayMode.NoThematicPolygons && !!multigraph
      }),
      shareReplay(1)
    )

    this.watch(visible$, (visible) => {
      layer.setVisible(visible)
    })

    const compareFeature = (feature1: any, feature2: any) => {
      if (feature1 == feature2) {
        return true
      }

      return JSON.stringify(feature1) === JSON.stringify(feature2)
    }

    const interaction = new FeatureInteractionLayer(layer, { compareFeature })
    const hoverSource = new GeojsonMapSource(
      combineLatest(interaction.events.hover, this.appModel.settings.cellHoverUpdates).pipe(
        map(([event, showHover]) => {
          if (!showHover || !event.feature) {
            return EMPTY_GEOJSON_DATA
          } else {
            return event.feature
          }
        }),
        distinctUntilChanged(),
        debounceTime(10)
      )
    )

    let hoverLayerConfig = {
      type: 'fill',
      paint: {
        'fill-opacity': 1,
        'fill-color': 'rgba(255, 255, 0, 0.7)',
        'fill-outline-color': 'rgba(255, 255, 0, 1)',
      },
    }

    new DefaultMapLayer(this.map, hoverSource, hoverLayerConfig)

    return { layer, interaction }
  }

  async initMobile() {
    const field = (await this.auth.isAdmin()) ? 'preview' : 'active'

    try {
      const meta = await this.modileCatchmentsEndpoint.meta()
      new MobileCatchmentsComposite(
        this.map as any,
        this.appModel.places.sources,
        field,
        meta,
        this.modileCatchmentsEndpoint
      )
    } catch (e) {
      console.error(e)
    }
  }

  initPolygons() {
    const options: any = {
      buffer: 0.0004,
      srid: SRID.SRID_4326,
      simplifyMeters: 2,
    }

    const settingsStore = this.appModel.settings

    const polygonOptions: Observable<PolygonMapLayerOptions> = Observable.combineLatest(
      settingsStore.edgeWeightsUpdates,
      settingsStore.travelOpacityUpdates
    ).map(([colors, opacity]) => {
      return {
        ...options,

        edgeWeights: colors,
        outline: false,
        fillOpacity: opacity,
      }
    })

    const layer = new DefaultPolygonsLayer(this.map, this.appModel.polygons, polygonOptions)

    this.watch(this.featureModel.polygonsVisibleUpdates, (visible) => {
      // layer.setVisible(mode !== TravelDisplayMode.ThematicNoPolygons)
      layer.setVisible(visible)
    })

    this.watch(this.featureModel.travelDisplayModeUpdates, (mode) => {
      layer.setInverse(mode === TravelDisplayMode.ThematicPolygonsInverted)
    })

    return layer
  }

  setVisible(value: boolean): this {
    throw new Error('Method not implemented.')
  }

  isVisible(): boolean {
    throw new Error('Method not implemented.')
  }
}
